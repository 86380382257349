<template>
  <div class="see-party">
    <div class="container">
      <div class="content" v-for="(item, index) in partyData" :key="index">
        <h5>{{ index == "baseInfo" ? title[0] : title[1] }}</h5>
        <ul class="base">
          <li v-for="(subItem, subIndex) in item" :key="subIndex">
            <span class="label">{{ subItem.label }}:</span>
            <span class="data">{{ subItem.value ? subItem.value : "无" }}</span>
          </li>
        </ul>
      </div>
       <Spin fix v-if="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
export default {
    props: ['partyInfo'],
  data() {
    return {
      spinShow: false,
      title: ["基本信息", "党务信息"],
      partyData: {
        baseInfo: {
          userCode: {
            label: "档案编号",
            value: "",
          },
          projectCode: {
            label: "所属社区",
            value: "",
          },
          name: {
            label: "姓名",
            value: "",
          },
          sex: {
            label: "性别",
            value: "",
          },
          birthday: {
            label: "出生日期",
            value: "",
          },
          national: {
            label: "民族",
            value: "",
          },
          hometown: {
            label: "籍贯",
            value: "",
          },
          idNum: {
            label: "身份证号码",
            value: "",
          },
          eduDegree: {
            label: "学历",
            value: "",
          },
          account: {
            label: "手机号码",
            value: "",
          },
          workUnit: {
            label: "工作单位",
            value: "",
          },
          skill: {
            label: "职业分类",
            value: "",
          },
          post: {
            label: "单位职务",
            value: "",
          },
        },
        partyInfo: {
          cbranch: {
            label: "所在党支部",
            value: "",
          },
          communistPost: {
            label: "党内职务",
            value: "",
          },
          joinDate: {
            label: "入党时间",
            value: "",
          },
          unitTel: {
            label: "单位党组织联系电话",
            value: "",
          },
          joinType: {
            label: "进入支部类型",
            value: "",
          },
          leaveType: {
            label: "转离支部类型",
            value: "",
          },
          joinService: {
            label: "是否加入党员服务队",
            value: "",
          },
          serviceTime: {
            label: "能参加社区服务时间",
            value: "",
          },
          serviceIntention: {
            label: "服务意向",
            value: "",
          },
        }
      },
    };
  },
  created() {
    this.spinShow = true;
    //根据getPartyData的数据匹配数据字典中的数据
    Promise.all([
      this.partyPosition(),
      this.entrybranchType(),
      this.leavebranchType(),
      this.service(),
      this.getCommunity(),
      this.getEducation(),
      this.getOccupational(),
      this.getUnit(),
      this.getNation(),
      this.getPartyData(),
    ]).then((value) => {
      if (
        value[0].code == 200 &&
        value[1].code == 200 &&
        value[2].code == 200 &&
        value[3].code == 200 &&
        value[4].code == 200 &&
        value[5].code == 200 &&
        value[6].code == 200 &&
        value[7].code == 200 &&
        value[8].code == 200 &&
        value[9].code == 200
      ) {
        if (value[9].data["birthday"]) {
          let time = new Date(value[9].data["birthday"]);
          value[9].data["birthday"] =
            time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();
        }
        if (value[9].data.communistPost) {
          value[9].data.communistPost =
            value[0].dataList[
              parseInt(value[9].data.communistPost) - 1
            ].dictValue;
        }
        if (value[9].data.joinType) {
          value[9].data.joinType =
            value[1].dataList[parseInt(value[9].data.joinType) - 1].dictValue;
        }
        if (value[9].data.leaveType) {
          value[9].data.leaveType =
            value[2].dataList[parseInt(value[9].data.leaveType) - 1].dictValue;
        }
        if (value[9].data.serviceTime) {
          value[9].data.serviceTime =
            value[3].dataList[
              parseInt(value[9].data.serviceTime) - 1
            ].dictValue;
        }
        if (value[9].data.joinService) {
          value[9].data.joinService =
            value[9].data.joinService == 1 ? "是" : "否";
        }
        if (value[9].data.projectCode) {
          value[4].dataList.map((item, index) => {
            if (value[9].data.projectCode == item.code) {
              value[9].data.projectCode = item.fullName;
            }
          });
        }
        if (value[9].data.eduDegree) {
          value[9].data.eduDegree =
            value[5].dataList[parseInt(value[9].data.eduDegree) - 1].name;
        }
        if (value[9].data.skill) {
          value[9].data.skill =
            value[6].dataList[parseInt(value[9].data.skill) - 1].dictValue;
        }
        if (value[9].data.post) {
          value[9].data.post =
            value[7].dataList[parseInt(value[9].data.post) - 1].dictValue;
        }
        if (value[9].data.national) {
          value[9].data.national =
            value[8].dataList[parseInt(value[9].data.national) - 1].dictValue;
        }
        for (let k in value[9].data) {
          if (this.partyData.baseInfo.hasOwnProperty(k)) {
            this.partyData.baseInfo[k].value = value[9].data[k];
          } else if (this.partyData.partyInfo.hasOwnProperty(k)) {
            this.partyData.partyInfo[k].value = value[9].data[k];
          }
        }
        this.spinShow = false;
      }
    });
  },
  methods: {
    getPartyData() {
      let userData = this.partyInfo;
      return this.$get("/syaa/pc/sy/user/communist/selectByUserId", userData)
    },
    //获取党位职务
    partyPosition() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNIST_POST"
      });
    },
    //进入支部类型
    entrybranchType() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNSIT_JOIN_TYPE"
      });
    },
    //转离支部类型
    leavebranchType() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNSIT_LEAVE_TYPE"
      });
    },
    //能参加社区服务的时间
    service() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNSIT_SERVICE_TIME",
      });
    },

    //获取所属社区列表
    getCommunity() {
      let staffId = parent.vue.loginInfo.userinfo.id;
      return this.$get("/old/api/pc/project/selectStaffManagerProjectAndOrg", {
        staffId: staffId,
        orgCode:parent.vue.loginInfo.userinfo.orgCode
      });
    },

    //获取学历列表
    getEducation() {
      return this.$get("/old/api/common/sys/dictList", {
        groupCode: "EDU_DEGREE",
      });
    },

    //获取职业分类列表
    getOccupational() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNIST_SKILL",
      });
    },

    //获取单位职务
    getUnit() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "COMMUNIST_POST",
      });
    },

    //获取民族列表
    getNation() {
      return this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "NATIONAKLITY",
      });
    },
  },
};
</script>
    
<style scoped lang='less'>

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.ivu-spin {
  color: #12b26d;
  font-size: 22px;
}

.see-party {
  position: relative;
  background: #efefef;
  text-align: left;
  h3 {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #fff;
    padding-left: 50px;
    font-size: 18px;
  }
  .container {
      height: calc(100vh - 75px);
    background: #fff;
    overflow: auto;
    box-shadow: 0 0 10px rgba(168, 162, 162, 0.3);
    border-radius: 8px;
    .content {
      margin: 0 50px;
      padding-bottom: 20px;
      border-bottom: 2px solid #efefef;
      &:last-child {
        border-bottom: 0;
      }
      h5 {
        margin-top: 30px;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .base {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 33.3%;
        line-height: 50px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        .label {
          margin-right: 10px;
        }
        .data {
          flex: 1;
        }
      }
    }
  }
}
</style>